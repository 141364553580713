body {
  margin: 0;
  font-family: Inter !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(218,240,236);
background: linear-gradient(105deg, rgba(218,240,236,1) 0%, rgba(255,255,255,1) 100%);
overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light{
  background-color: transparent !important;
}
button{
  background-color: #54BD95 !important;
  border: none !important;
}

h1{
  font-weight: 700 !important;
  font-size: 80px !important;
  line-height: 90px !important;
}

.custom-card {
  border-radius: 10px;
  background-color: #fff;
  max-height: 70px;
  margin-top: 400px;
  margin-right: -35px;
  z-index: 99999;
}

@media (max-width: 1499px) {
  .custom-card {
    display: none;
  }
}

.address-container {
  padding: 20px;
  margin-bottom: 20px;
}

.address-details {
  font-size: 16px;
}

.address-details p {
  margin: 5px 0;
}

ul {
  list-style-type: none;
}
.brand{
  margin-top: 100px;
}

.features{
  margin-top: 200px
}

.diff{
  margin-top: 100px;
}

.custom-col.col{
  margin-left: 100px !important;
}
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #2d9f72;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2d9f72;
}

.btn-primary{
  background-color: #54BD95 !important;
  border: none !important
}